$( document ).ready(function() {

  // Get started!
 $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});
    
    $('.nav-link').on('click',function() {
  $('.navbar-collapse').collapse('hide');
});
    

});
